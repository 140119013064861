import { AgentStatusType, AgentStatusTypes } from "../interfaces/agentStatus";

export const getAgentStatusType = (name: string, status: AgentStatusType): AgentStatusType => {
  if (status === 'ROUTABLE' && (name === 'Connected' || name === 'On Hold')) {
    return AgentStatusTypes.OnCall;
  }

  if(status === 'ROUTABLE' && name === 'ACW') {
    return AgentStatusTypes.ACW;
  }

  return status;
};
